import React, { ReactNode } from "react";
import { Paper, Stack } from "@mui/material";
import { dashboardSettings } from "../utils/dashboardSettingsConstants";

interface ResultContainerProps {
  children: ReactNode;
}

export const ResultContainer: React.FC<ResultContainerProps> = ({
  children,
}) => {
  return (
    <Paper
      sx={{
        width: dashboardSettings.containerWidth,
        m: "auto",
        py: 3,
        mt: 3,
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        alignItems="center"
        justifyContent="space-evenly"
        flexWrap="wrap"
      >
        {children}
      </Stack>
    </Paper>
  );
};
