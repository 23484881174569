import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InvestmentCard } from "./InvestmentCard";
import { CardTitle } from "../Cardtitle";
import { investmentColors } from "../../utils/colorConstants";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";

export const InvestmentData = () => {
    const { t } = useTranslation();
    const { data } = useContext(DataContext);
    return (
        data?.investmentData && (
            <Stack alignItems="center" width="25%" position="relative">
                <Stack mb={15}>
                    <CardTitle tColor={investmentColors.title} text={t("investmentCard.cardTitle")} />
                </Stack>
                <InvestmentCard />
            </Stack>
        )
    );
};
