import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AttributionFilterOption, DataContext } from "../contexts/DataContext";

export const SideBarAttributionCard = () => {
    const { t } = useTranslation();
    const { attributionFilter, setAttributionFilter } = useContext(DataContext);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value as AttributionFilterOption;
        setAttributionFilter(newValue);
    };

    return (
        <Stack height="170px" overflow="auto">
            <Typography fontSize="16px" mb={2}>
                {" "}
                {t("sideBar.attribution")}
            </Typography>
            <FormControl>
                <RadioGroup value={attributionFilter} name="attribution-group" onChange={handleChange}>
                    <FormControlLabel value={"noFilter"} control={<Radio />} label={t("attributionOptions.noFilter")} />
                    <FormControlLabel value={"committed"} control={<Radio />} label={t("attributionOptions.committed")} />
                    <FormControlLabel value={"outstanding"} control={<Radio />} label={t("attributionOptions.outstanding")} />
                </RadioGroup>
            </FormControl>
        </Stack>
    );
};
