import { Stack, Typography } from "@mui/material";
import { ResultCardContainer } from "../components/ResultCardContainer";
import { ResultContainer } from "../components/ResultContainer";
import { CustomTableContainer } from "../components/TableContainer";
import { ValueAddedCard } from "../components/ValueAddedCard";
import { CustomTable } from "../components/customoverviewtable/CustomTable";
import { ResultSummary } from "../components/resultsummary/ResultSummary";
import { chartColors, valueAddedColors } from "../utils/colorConstants";
import WaterfallChart from "../components/WaterfallChart";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DataContext } from "../contexts/DataContext";
import { MenuContext } from "../App";

export const ValueAdded = () => {
    const { data } = useContext(DataContext);
    const { t } = useTranslation();
    const { setSelectedMenu } = useContext(MenuContext);
    useEffect(() => {
        setSelectedMenu("valueAdded");
    }, [setSelectedMenu]);
    return (
        data && (
            <Stack>
                <ResultContainer>
                    <ResultCardContainer>
                        <ValueAddedCard />
                    </ResultCardContainer>
                    <ResultCardContainer>
                        <Typography fontSize="16px" fontWeight={600}>
                            {t("chart.valueAdded.individualClientsTitle")}
                        </Typography>
                        <WaterfallChart
                            data={data.valueAddedIndividualClientsChartData}
                            translationPrefix="chart.valueAdded"
                            chartColors={chartColors.valueAdded}
                            showLegend
                            rotateXaxis={false}
                            chartTextColor="gray"
                            yAxisTitle="# (000’s) USD"
                        />
                        <ResultSummary
                            dataType={"valueadded"}
                            bgColor={valueAddedColors.tableBg}
                            data={data.valueAddedIndividualClientsChartData}
                            translationPrefix="chart.valueAdded"
                            columnSpacing={2}
                            columnRightPadding={4}
                            showInMillions={true}
                        />
                    </ResultCardContainer>
                    <ResultCardContainer>
                        <Typography fontSize="16px" fontWeight={600}>
                            {t("chart.ghg.sectoralExposureTitle")}
                        </Typography>
                        <WaterfallChart
                            data={data.valueAddedSectoralExposureChartData}
                            translationPrefix="chart.valueAdded"
                            chartColors={chartColors.valueAdded}
                            showLegend
                            rotateXaxis={false}
                            chartTextColor="gray"
                            yAxisTitle="# (000’s) USD"
                        />
                        <ResultSummary
                            dataType={"valueadded"}
                            bgColor={valueAddedColors.tableBg}
                            data={data.valueAddedSectoralExposureChartData}
                            translationPrefix="chart.valueAdded"
                            columnSpacing={2}
                            columnRightPadding={4}
                            showInMillions={true}
                        />
                    </ResultCardContainer>
                </ResultContainer>
                <CustomTableContainer>
                    <CustomTable dataType="valueadded" tableData={data.valueAddedTableData} />
                </CustomTableContainer>
            </Stack>
        )
    );
};
