import { useContext } from "react";

import Typography from "@mui/material/Typography";
import { Divider, Stack } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

import uploadIcon from "../assets/imgs/uploadIcon.png";
import logo from "../assets/imgs/logo.svg";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { dashboardSettings } from "../utils/dashboardSettingsConstants";
import { MenuContext } from "../App";
import { DataContext } from "../contexts/DataContext";

export const Link = (props: React.ComponentProps<typeof RouterLink>) => {
    return <RouterLink {...props} style={{ textDecoration: "none", color: "black" }} />;
};
export const Header = () => {
    const { t } = useTranslation();

    const { selectedMenu } = useContext(MenuContext);
    const dataContext = useContext(DataContext);
    const uploaded = !!dataContext.data;
    return (
        <Stack height="100px" width="100%" boxShadow="0px 4px 6px 0px rgba(0,0,0,0.25)" sx={{ backgroundColor: "#FFFFFF" }}>
            <Stack width={dashboardSettings.containerWidth} direction="row" alignItems="center" m="auto" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={2} sx={{ cursor: "pointer" }}>
                    <img src={logo} alt="Logo"></img>
                    <Link to="/upload" style={{ textDecoration: "none", color: "black" }}>
                        <Typography fontSize="24px" color="#C82C3C">
                            {t("header.newFile")}
                            <img src={uploadIcon} alt="Upload Icon" style={{ marginLeft: "1rem" }}></img>
                        </Typography>
                    </Link>
                </Stack>
                {uploaded && (
                    <Stack direction="row" alignItems="center" spacing={7} mx={3} ml="auto">
                        <Stack>
                            <Link to="/dashboard">
                                <Typography fontSize="24px"> {t("header.overview")}</Typography>
                            </Link>
                        </Stack>
                        <Stack>
                            <Link to="/ghg">
                                <Typography fontSize="24px" color={selectedMenu === "ghg" ? "#407243" : "222222"}>
                                    {t("header.ghg")}
                                </Typography>
                            </Link>
                        </Stack>
                        <Stack>
                            <Link to="/jobs">
                                <Typography fontSize="24px" color={selectedMenu === "jobs" ? "#96212D" : "222222"}>
                                    {t("header.jobs")}
                                </Typography>
                            </Link>
                        </Stack>
                        <Stack>
                            <Link to="/valueadded">
                                <Typography fontSize="24px" color={selectedMenu === "valueAdded" ? "#E38B06" : "222222"}>
                                    {t("header.valueadded")}
                                </Typography>
                            </Link>
                        </Stack>
                    </Stack>
                )}
                {uploaded && <Divider orientation="vertical" variant="middle" flexItem sx={{ border: "solid 1px", mx: 3 }} />}
                <Stack direction="row" alignItems="center" spacing={1} sx={{ cursor: "pointer" }}>
                    <Link to="/api/auth/logout" reloadDocument>
                        <Typography fontSize="24px" display="flex" alignItems="center">
                            {t("header.logout")} <LogoutIcon sx={{ ml: 1 }} />
                        </Typography>
                    </Link>
                </Stack>
            </Stack>
        </Stack>
    );
};
