import { Stack, Typography } from "@mui/material";
import { GhgEmissionsCard } from "../components/GhgEmissionsCard";
import { ResultContainer } from "../components/ResultContainer";
import { CustomTableContainer } from "../components/TableContainer";
import { ResultCardContainer } from "../components/ResultCardContainer";
import { useContext, useEffect } from "react";
import { DataContext } from "../contexts/DataContext";
import WaterfallChart from "../components/WaterfallChart";
import { CustomTable } from "../components/customoverviewtable/CustomTable";
import { ResultSummary } from "../components/resultsummary/ResultSummary";
import { chartColors, emissionsColors } from "../utils/colorConstants";
import { useTranslation } from "react-i18next";
import { MenuContext } from "../App";

export const Ghg = () => {
    const { data } = useContext(DataContext);
    const { setSelectedMenu } = useContext(MenuContext);
    const { t } = useTranslation();
    useEffect(() => {
        setSelectedMenu("ghg");
    }, [setSelectedMenu]);
    return (
        data && (
            <Stack>
                <ResultContainer>
                    <ResultCardContainer>
                        <GhgEmissionsCard />
                    </ResultCardContainer>
                    <ResultCardContainer>
                        <Typography fontSize="16px" fontWeight={600}>
                            {t("chart.ghg.individualClientsTitle")}
                        </Typography>
                        <WaterfallChart
                            data={data.ghgIndividualClientsChartData}
                            translationPrefix="chart.ghg"
                            chartColors={chartColors.ghg}
                            showLegend
                            rotateXaxis={false}
                            chartTextColor="white"
                            yAxisTitle="TCO2EQ"
                        />
                        <ResultSummary
                            dataType={"emissions"}
                            bgColor={emissionsColors.tableBg}
                            data={data.ghgIndividualClientsChartData}
                            translationPrefix="chart.ghg"
                            columnSpacing={3.8}
                            columnRightPadding={5}
                        />
                    </ResultCardContainer>
                    <ResultCardContainer>
                        <Typography fontSize="16px" fontWeight={600}>
                            {t("chart.ghg.sectoralExposureTitle")}
                        </Typography>
                        <WaterfallChart
                            data={data.ghgSectoralExposureChartData}
                            translationPrefix="chart.ghg"
                            chartColors={chartColors.ghg}
                            showLegend
                            rotateXaxis={false}
                            chartTextColor="white"
                            yAxisTitle="TCO2EQ"
                        />
                        <ResultSummary
                            dataType={"emissions"}
                            bgColor={emissionsColors.tableBg}
                            data={data.ghgSectoralExposureChartData}
                            translationPrefix="chart.ghg"
                            columnSpacing={3.8}
                            columnRightPadding={5}
                        />
                    </ResultCardContainer>
                </ResultContainer>
                <CustomTableContainer>
                    <CustomTable dataType="emissions" tableData={data.ghgTableData} />
                </CustomTableContainer>
            </Stack>
        )
    );
};
