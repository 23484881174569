import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button, Paper, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { read } from "xlsx";
import { MenuContext } from "../App";
import { DataContext } from "../contexts/DataContext";
import { dashboardSettings, uploadContainerSettings } from "../utils/dashboardSettingsConstants";
import { processXlsx } from "../utils/processing";

export const UploadResult = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [file, setFile] = useState<null | File>(null);

    const { setSelectedMenu } = useContext(MenuContext);
    const { showExampleData, rawDataReceived } = useContext(DataContext);

    useEffect(() => {
        setSelectedMenu("upload");
    }, [setSelectedMenu]);

    const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFile(e.target.files?.item(0) || null);
    };
    return (
        <Stack width={dashboardSettings.containerWidth} m="auto" spacing={2} mt={5}>
            <Paper sx={uploadContainerSettings.style}>
                <Stack p={3} spacing={2}>
                    <Typography fontSize="32px" fontWeight={500}>
                        {t("upload.input")}
                    </Typography>
                    <Typography fontSize="12px">{t("upload.description")}</Typography>
                    <Stack spacing={2}>
                        <input onChange={handleFileSelected} type="file" />
                        <Stack direction="row">
                            <Button
                                variant="contained"
                                size="small"
                                sx={{ width: "98px" }}
                                onClick={async () => {
                                    if (file !== null) {
                                        const ab = await file.arrayBuffer();
                                        const wb = read(ab, { type: "array" });
                                        const rawData = processXlsx(wb);
                                        rawDataReceived(rawData);
                                        navigate("/dashboard");
                                    }
                                }}
                            >
                                {t("upload.button")}
                            </Button>
                            <Button variant="contained" size="small" onClick={() => showExampleData()}>
                                {t("upload.showExampleData")}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
            <Paper sx={{ width: "320px", p: 3 }}>
                <Stack spacing={2}>
                    <Typography fontSize="12px">{t("downloadFile.description")}</Typography>
                    <Button variant="outlined" size="small" startIcon={<FileDownloadIcon />}>
                        {t("downloadFile.button")}
                    </Button>
                </Stack>
            </Paper>
        </Stack>
    );
};
