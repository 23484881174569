import { Stack } from "@mui/material";
import { CardTitle } from "./Cardtitle";
import { ResultCard } from "./ResultCard";
import { ResultSummary } from "./resultsummary/ResultSummary";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DataContext } from "../contexts/DataContext";
import { employmentColors } from "../utils/colorConstants";
import { resultCardSettings } from "../utils/dashboardSettingsConstants";

export const EmploymentCard = () => {
    const { t } = useTranslation();
    const { data } = useContext(DataContext);
    const employmentSummary = data?.employmentSummary;
    return (
        employmentSummary && (
            //@ts-ignore
            <Stack {...resultCardSettings}>
                <CardTitle tColor={employmentColors.title} text={t("employmentCard.cardTitle")} />
                <ResultCard
                    bgColor={employmentColors.cardBg}
                    value={employmentSummary?.columnTotals[0] ? t("intlNumber", { val: employmentSummary?.columnTotals[0].toFixed(0) }) : "n/a"}
                    description={t("employmentCard.resultCard.description")}
                />
                <ResultSummary
                    dataType="employment"
                    bgColor={employmentColors.tableBg}
                    data={employmentSummary}
                    translationPrefix="employmentCard.resultSummary"
                    columnSpacing={7}
                    columnRightPadding={0}
                    isDashboard
                />
            </Stack>
        )
    );
};
