import { createContext, useEffect, useState } from "react";
import { BrowserRouter, Link, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { AlertProvider } from "./contexts/AlertContext";
import AlertPopup from "./components/AlertPopup";
import { Ghg } from "./pages/Ghg";
import { Jobs } from "./pages/Jobs";
import { ValueAdded } from "./pages/ValueAdded";
import { Dashboard } from "./pages/Dashboard";
import { Upload } from "./pages/Upload";
import { Header } from "./components/Header";
import { DataProvider } from "./contexts/DataContext";
import { Box, Stack, ThemeProvider, Typography, createTheme } from "@mui/material";

import { SideBar } from "./components/SideBar";
import { GetExcelOutputNotification } from "./components/GetExcelOutputNotification";
import { UploadResult } from "./pages/UploadResult";
import { Widgets } from "./pages/Widgets";
import { ChangeLog } from "./pages/Changelog";
import { Changes } from "./utils/ChangeLogConstants";

const theme = createTheme({
    typography: {
        fontFamily: "Poppins",
    },
});
type SelectedMenu = "overview" | "ghg" | "jobs" | "valueAdded" | "upload";

type MenuContextType = {
    selectedMenu: SelectedMenu;
    setSelectedMenu: (s: SelectedMenu) => void;
};

const defaultSelectedMenu: SelectedMenu = "overview";

export const MenuContext = createContext<MenuContextType>({
    selectedMenu: defaultSelectedMenu,
    setSelectedMenu: (defaultSelectedMenu: SelectedMenu) => {},
});

export const Layout = () => {
    const [selectedMenu, setSelectedMenu] = useState<SelectedMenu>(defaultSelectedMenu);
    const location = useLocation();
    useEffect(() => {
        fetch("/api/auth/userinfo", {})
            .then((response) => {
                if (response.ok) {
                } else if (response.status === 401) {
                    window.location.href = "/api/auth/login";
                }
            })
            .catch(() => {});
    });

    return (
        <MenuContext.Provider value={{ selectedMenu, setSelectedMenu }}>
            <Stack position="relative" minHeight="100vh">
                <Box position="relative">
                    <Header />
                    <SideBar />
                    {<GetExcelOutputNotification />}
                    <Outlet />
                </Box>
                <Typography
                    position="absolute"
                    bottom={location.pathname === "/upload" || location.pathname === "/" ? "0px" : "-100px"}
                    left="50%"
                    style={{ transform: "translate(-50%,-50%)" }}
                >
                    Joint Impact Model v. {Changes[0].version} - <Link to="/changelog">last update {Changes[0].date}</Link>
                </Typography>
            </Stack>
        </MenuContext.Provider>
    );
};

const DataSection = () => {
    return (
        <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/ghg" element={<Ghg />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/valueadded" element={<ValueAdded />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/uploadresult" element={<UploadResult />} />
            <Route path="/widgets" element={<Widgets />} />
        </Routes>
    );
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <AlertProvider>
                <AlertPopup />
                <DataProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Layout />}>
                                <Route index element={<Upload />} />
                                <Route path="/changelog" element={<ChangeLog />} />
                                <Route path="*" element={<DataSection />} />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </DataProvider>
            </AlertProvider>
        </ThemeProvider>
    );
}

export default App;
