import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { createObjectData, createTableAndChartData } from "../utils/processing";
import * as columns from "../utils/columnConstants";
import type { Scope } from "../utils/scopes";

export type Summary = {
    columns: string[];
    data: {
        title: string;
        values: (number | undefined)[];
    }[];
    columnTotals: (number | undefined)[];
};

export type SummaryWithTotals = {
    columns: string[];
    data: {
        title: string;
        values: (number | undefined)[];
        total: number | undefined;
    }[];
    columnTotals: (number | undefined)[];
    total: number | undefined;
};
export type InvestmentDataType = {
    clients: number | undefined;
    countries: number | undefined;
    totalRevenue: number | undefined;
    totalOutstanding: number | undefined;
};

export type ExcelJsonRow = {
    [columns.Indicator]: string;
    [columns.SubIndicator]: string;
    [columns.Scope]: string;
    [columns.SubScope]: string;
    [columns.LDC]: string;
    [columns.Income]: string;
    [columns.Region]: string;
    [columns.SubRegion]: string;
    [columns.Country]: string;
    [columns.EconAct]: string;
    [columns.EconActCode]: string;
    [columns.Client]: string;
    [columns.ClientType]: string;
    [columns.Investee]: string;
    [columns.Comment]: string;

    [columns.ReportingYear]: number | undefined;
    [columns.FiscalYear]: number | undefined;
    [columns.ProjectTimeline]: number | undefined;
    [columns.Revenue]: number | undefined;
    [columns.OutstandingAmount]: number | undefined;
    [columns.PowerProduction]: number | undefined;
    [columns.AttributedTotal]: number | undefined;
    [columns.Total]: number | undefined;
    [columns.YouthEmployment]: number | undefined;
    [columns.ConfidenceLevel]: number | undefined;
    [columns.ImpactOutstanding]: number | undefined;
    [columns.ImpactRevenue]: number | undefined;
    [columns.Sector1]: number | undefined;
    [columns.Sector2]: number | undefined;
    [columns.Sector3]: number | undefined;
    [columns.Sector4]: number | undefined;
    [columns.Sector5]: number | undefined;
    [columns.Sector6]: number | undefined;
    [columns.Sector7]: number | undefined;
    [columns.Sector8]: number | undefined;
    [columns.Sector9]: number | undefined;
    [columns.Sector10]: number | undefined;
    [columns.Sector11]: number | undefined;
    [columns.Sector12]: number | undefined;
    [columns.Sector13]: number | undefined;
    [columns.Sector14]: number | undefined;
    [columns.Sector15]: number | undefined;
    [columns.Sector16]: number | undefined;
    [columns.Sector17]: number | undefined;
    [columns.Sector18]: number | undefined;
    [columns.Sector19]: number | undefined;
    [columns.Sector20]: number | undefined;
    [columns.Sector21]: number | undefined;
    [columns.Sector22]: number | undefined;
    [columns.Sector23]: number | undefined;
    [columns.Sector24]: number | undefined;
    [columns.Sector25]: number | undefined;
    [columns.Sector26]: number | undefined;
    [columns.Sector27]: number | undefined;
    [columns.Sector28]: number | undefined;
    [columns.Sector29]: number | undefined;
    [columns.Sector30]: number | undefined;
    [columns.Sector31]: number | undefined;
    [columns.Sector32]: number | undefined;
    [columns.Sector33]: number | undefined;
    [columns.Sector34]: number | undefined;
    [columns.Sector35]: number | undefined;
    [columns.Sector36]: number | undefined;
    [columns.Sector37]: number | undefined;
    [columns.Sector38]: number | undefined;
    [columns.Sector39]: number | undefined;
    [columns.Sector40]: number | undefined;
    [columns.Sector41]: number | undefined;
    [columns.Sector42]: number | undefined;
    [columns.Sector43]: number | undefined;
    [columns.Sector44]: number | undefined;
    [columns.Sector45]: number | undefined;
    [columns.Sector46]: number | undefined;
    [columns.Sector47]: number | undefined;
    [columns.Sector48]: number | undefined;
    [columns.Sector49]: number | undefined;
    [columns.Sector50]: number | undefined;
    [columns.Sector51]: number | undefined;
    [columns.Sector52]: number | undefined;
    [columns.Sector53]: number | undefined;
    [columns.Sector54]: number | undefined;
    [columns.Sector55]: number | undefined;
    [columns.Sector56]: number | undefined;
    [columns.Sector57]: number | undefined;
    [columns.Sector58]: number | undefined;
    [columns.Sector59]: number | undefined;
    [columns.Sector60]: number | undefined;
    [columns.Sector61]: number | undefined;
    [columns.Sector62]: number | undefined;
    [columns.Sector63]: number | undefined;
    [columns.Sector64]: number | undefined;
    [columns.Sector65]: number | undefined;
    [columns.Sector66]: number | undefined;
    [columns.Sector67]: number | undefined;
    [columns.Sector68]: number | undefined;
    [columns.Sector69]: number | undefined;
    [columns.Sector70]: number | undefined;
    [columns.Sector71]: number | undefined;
    [columns.Sector72]: number | undefined;
    [columns.Sector73]: number | undefined;
    [columns.Sector74]: number | undefined;
    [columns.Sector75]: number | undefined;
    [columns.Sector76]: number | undefined;
    [columns.AttributionOutstanding]: number | undefined;
    [columns.AttributionShareCommitted]: number | undefined;
    [columns.AttributionShareOutstanding]: number | undefined;
};
export type ExcelJsonSheet = Array<ExcelJsonRow>;

export type ObjectData = {
    employment: EmploymentObjectData[];
    general: GeneralObjectData[];
    ghg: GhgObjectData[];
    valueAdded: ValueAddedObjectData[];
    attribution: AttributionObjectData[];
};

export type GeneralObjectData = {
    id: string;
    client: string;
    country: string;
    econAct: string;
    investee: string | undefined;
    scope: Scope;
    year: number | undefined;
    revenue: number | undefined;
    outstandingAmount: number | undefined;
    outstandingShare: number | undefined;
    committedShare: number | undefined;
};

export type GhgObjectData = {
    id: string;
    client: string;
    country: string;
    econAct: string;
    investee: string | undefined;
    scope: Scope;
    year: number | undefined;
    outstandingAmount: number | undefined;
    outstandingShare: number | undefined;
    committedShare: number | undefined;
    scope1: { co2: number | undefined; nonco2: number | undefined; total: number | undefined };
    scope2: { co2: number | undefined; nonco2: number | undefined; total: number | undefined };
    scope3: { co2: number | undefined; nonco2: number | undefined; total: number | undefined };
};

export type EmploymentObjectData = {
    id: string;
    client: string;
    country: string;
    econAct: string;
    investee: string | undefined;
    scope: Scope;
    year: number | undefined;
    outstandingAmount: number | undefined;
    outstandingShare: number | undefined;
    committedShare: number | undefined;
    direct: { total: number | undefined; female: number | undefined; formal: number | undefined };
    supply: { total: number | undefined; female: number | undefined; formal: number | undefined };
    induced: { total: number | undefined; female: number | undefined; formal: number | undefined };
    power: { total: number | undefined; female: number | undefined; formal: number | undefined };
    combined: { total: number | undefined; female: number | undefined; formal: number | undefined };
};

export type ValueAddedObjectData = {
    id: string;
    client: string;
    country: string;
    econAct: string;
    investee: string | undefined;
    scope: Scope;
    year: number | undefined;
    outstandingAmount: number | undefined;
    outstandingShare: number | undefined;
    committedShare: number | undefined;
    // no induced here
    direct: { savings: number | undefined; wages: number | undefined; taxes: number | undefined };
    supply: { savings: number | undefined; wages: number | undefined; taxes: number | undefined };
    power: { savings: number | undefined; wages: number | undefined; taxes: number | undefined };
    combined: { savings: number | undefined; wages: number | undefined; taxes: number | undefined };
};

export type AttributionObjectData = {
    id: string;
    client: string;
    country: string;
    econAct: string;
    year: number | undefined;
    outstandingAmount: number | undefined;
    outstandingShare: number | undefined;
    committedShare: number | undefined;
};

export type OverviewObjectData = {
    client: string;
    country: string;
    econAct: string;
    investee: string | undefined;
    outstandingAmount: number | undefined;
    ghg: GhgObjectData | undefined;
    employment: EmploymentObjectData | undefined;
    valueAdded: ValueAddedObjectData | undefined;
};

export type RawData = {
    employment: ExcelJsonSheet;
    ghg: ExcelJsonSheet;
    valueAdded: ExcelJsonSheet;
    general: ExcelJsonSheet;
    attribution: ExcelJsonSheet;
};

export type TableData = {
    ghg: SingleTableData;
    employment: SingleTableData;
    valueAdded: SingleTableData;
    overview: SingleTableData;
};

export type SingleTableData = {
    columns: any; //TODO improve typing here?
    data: (string | number | undefined)[][];
};

// TODO: Better name?
export type Data = {
    investmentData: InvestmentDataType;

    employmentSummary: Summary;
    ghgSummary: Summary;
    valueAddedSummary: Summary;

    ghgIndividualClientsChartData: SummaryWithTotals;
    ghgSectoralExposureChartData: SummaryWithTotals;

    employmentIndividualClientsTableData: SummaryWithTotals;
    employmentIndividualClientsChartData: SummaryWithTotals;
    employmentSectoralExposureTableData: SummaryWithTotals;
    employmentSectoralExposureChartData: SummaryWithTotals;

    valueAddedIndividualClientsChartData: SummaryWithTotals;
    valueAddedSectoralExposureChartData: SummaryWithTotals;

    ghgTableData: SingleTableData;
    employmentTableData: SingleTableData;
    valueAddedTableData: SingleTableData;
    overviewTableData: SingleTableData;
};

export const exampleData: Data = {
    investmentData: { clients: 10, countries: 15, totalRevenue: 40, totalOutstanding: 25 },
    employmentSummary: {
        columns: ["employmentTotal", "employmentIntensity"],
        data: [
            { title: "direct", values: [17965, 2000000] },
            { title: "induced", values: [17965, 2000000] },
            { title: "supplyChain", values: [7366, 2000000] },
            { title: "financeEnabling", values: [38921, 2000000] },
            { title: "powerEnabling", values: [7366, 2000000] },
        ],
        columnTotals: [64251, 10000000],
    },
    ghgSummary: {
        columns: ["emissionTotal", "emissionIntensity"],
        data: [
            { title: "scope1", values: [17965, 2000000] },
            { title: "scope2", values: [17965, 2000000] },
            { title: "scope3withAsterisk", values: [7366, 2000000] },
        ],
        columnTotals: [44000, 6000000],
    },
    valueAddedSummary: {
        columns: ["economicTotal", "economicIntensity"],
        data: [
            { title: "wages", values: [12899, 2000000] },
            { title: "savings", values: [17108, 2000000] },
            { title: "taxes", values: [3666, 2000000] },
        ],
        columnTotals: [33673, 6000000],
    },
    ghgIndividualClientsChartData: {
        columns: ["co2", "nonCo2"],
        data: [
            { title: "scope1", values: [623, 6327], total: 6950 },
            { title: "scope2", values: [3721, 18], total: 3739 },
            { title: "scope3", values: [6707, 14611], total: 21318 },
        ],
        columnTotals: [11051, 20956],
        total: 32007,
    },
    ghgSectoralExposureChartData: {
        columns: ["co2", "nonCo2"],
        data: [
            { title: "scope1", values: [623, 6327], total: 6950 },
            { title: "scope2", values: [3721, 18], total: 3739 },
            { title: "scope3", values: [6707, 14611], total: 21318 },
        ],
        columnTotals: [11051.555, 20956.555],
        total: 32007.555,
    },
    employmentIndividualClientsTableData: {
        columns: ["ofWhichFemale", "ofWhichFormal"],
        data: [
            { title: "direct", values: [0, 541], total: 541 },
            { title: "induced", values: [125, 588], total: 588 },
            { title: "supplyChain", values: [344, 1114], total: 1114 },
            { title: "financeEnabling", values: [867, 11285], total: 11285 },
            { title: "powerEnabling", values: [867, 11285], total: 11285 },
        ],
        columnTotals: [13036, 13528],
        total: 13528,
    },
    employmentIndividualClientsChartData: {
        columns: ["clients"],
        data: [
            { title: "direct", values: [541], total: 541 },
            { title: "induced", values: [588], total: 588 },
            { title: "supplyChain", values: [1114], total: 1114 },
            { title: "financeEnabling", values: [11285], total: 11285 },
            { title: "powerEnabling", values: [11285], total: 11285 },
        ],
        columnTotals: [24813],
        total: 24813,
    },
    employmentSectoralExposureTableData: {
        columns: ["ofWhichFemale", "ofWhichFormal"],
        data: [
            { title: "direct", values: [0, 917], total: 917 },
            { title: "induced", values: [125, 1064], total: 1064 },
            { title: "supplyChain", values: [222, 1180], total: 1180 },
        ],
        columnTotals: [1392, 3161],
        total: 3161,
    },
    employmentSectoralExposureChartData: {
        columns: ["clients", "total"],
        data: [
            { title: "direct", values: [917], total: 917 },
            { title: "induced", values: [1064], total: 1064 },
            { title: "supplyChain", values: [1180], total: 1180 },
        ],
        columnTotals: [3161],
        total: 3161,
    },
    valueAddedIndividualClientsChartData: {
        columns: ["wages", "savings", "taxes"],
        data: [
            { title: "direct", values: [2417, 8660, 1576], total: 12652 },
            { title: "supplyChain", values: [5975, 10307, 562], total: 16844 },
        ],
        columnTotals: [10091, 22670, 3099],
        total: 35860,
    },
    valueAddedSectoralExposureChartData: {
        columns: ["wages", "savings", "taxes"],
        data: [
            { title: "direct", values: [8060, 10516, 2051], total: 20627 },
            { title: "supplyChain", values: [4839, 6592, 1615], total: 13046 },
        ],
        columnTotals: [12699, 17106, 3666],
        total: 33673,
    },

    valueAddedTableData: {
        columns: [
            { filter: true, columns: ["client", "country", "activity", "totalOutstanding"] },
            {
                color: "valueAdded",
                header: "direct",
                columns: ["savings", "taxes", "wages", "totalContribution"],
            },
            {
                color: "valueAdded",
                header: "supplyChain",
                columns: ["savings", "taxes", "wages", "totalContribution"],
            },

            {
                color: "valueAdded",
                connected: true,
                columns: ["economicIntensity"],
            },
        ],
        data: [
            [
                "Accounting, accounting and auditing activities",
                "egypt",
                "econAct1",
                2222222,
                2222222,
                2222222,
                2222222,
                2222222,
                2222222,
                2222222,
                2222222,
                2222222,
                2222222,
            ],
            [
                "Accounting, accounting and auditing activities",
                "gambia",
                "econAct1",
                1111111,
                1111111,
                1111111,
                1111111,
                1111111,
                1111111,
                1111111,
                1111111,
                1111111,
                1111111,
            ],
            [
                "Accounting, accounting and auditing activities",
                "egypt",
                "econAct2",
                3333333,
                3333333,
                3333333,
                3333333,
                3333333,
                3333333,
                3333333,
                3333333,
                3333333,
                3333333,
            ],
        ],
    },
    ghgTableData: {
        columns: [
            { filter: true, columns: ["client", "country", "activity", "totalOutstanding"] },
            {
                color: "emission",
                header: "direct",
                columns: ["scope1emissions"],
            },
            {
                color: "emission",
                header: "supplyChain",
                columns: ["scope2emissions", "scope3emissions"],
            },

            {
                color: "emission",
                connected: true,
                columns: ["scope1&2", "scope1&2&3", "scope1&2(mUSD)"],
            },
        ],
        data: [
            ["Accounting, accounting and auditing activities", "egypt", "econAct1", 2222222, 2222222, 2222222, 2222222, 2222222, 2222222, 2222222],
            ["Accounting, accounting and auditing activities", "gambia", "econAct1", 1111111, 1111111, 1111111, 1111111, 1111111, 1111111, 1111111],
            ["Accounting, accounting and auditing activities", "egypt", "econAct2", 3333333, 3333333, 3333333, 3333333, 3333333, 3333333, 3333333],
        ],
    },
    employmentTableData: {
        columns: [
            { filter: true, columns: ["client", "country", "activity", "totalOutstanding"] },
            {
                color: "employment",
                header: "direct",
                columns: ["total"],
            },
            {
                color: "employment",
                header: "supplyChain",
                columns: ["total"],
            },
            {
                color: "employment",
                header: "induced",
                columns: ["total"],
            },
            {
                color: "employment",
                header: "powerEnabling",
                columns: ["total"],
            },
            {
                color: "employment",
                header: "finance",
                columns: ["total"],
            },

            {
                color: "employment",
                connected: true,
                columns: ["employmentIntensity"],
            },
        ],
        data: [
            ["Accounting, accounting and auditing activities", "egypt", "econAct1", 2222222, 2222222, 2222222, 2222222, 2222222, -1, 2222222],
            ["Accounting, accounting and auditing activities", "gambia", "econAct1", 1111111, 1111111, 1111111, 1111111, 1111111, -1, 1111111],
            ["Accounting, accounting and auditing activities", "egypt", "econAct2", 3333333, 3333333, 3333333, 3333333, 3333333, -1, 3333333],
        ],
    },
    overviewTableData: {
        columns: [
            { filter: true, columns: ["client", "country", "activity", "totalOutstanding"] },
            {
                color: "emission",
                header: "ghgEmissions",
                columns: ["scope1&2", "scope1&2&3", "scope1&2(mUSD)"],
            },
            {
                color: "employment",
                header: "employment",
                columns: ["totalEmployment", "employmentIntensity"],
            },

            {
                color: "valueAdded",
                header: "valueAdded",
                columns: ["totalContribution", "economicIntensity"],
            },
        ],
        data: [
            ["Accounting, accounting and auditing activities", "egypt", "econAct1", 2222222, 2222222, 2222222, 2222222, 2222222, 2222222, 2222222, 2222222],
            ["Accounting, accounting and auditing activities", "gambia", "econAct1", 1111111, 1111111, 1111111, 1111111, 1111111, 1111111, 1111111, 1111111],
            ["Accounting, accounting and auditing activities", "egypt", "econAct2", 3333333, 3333333, 3333333, 3333333, 3333333, 3333333, 3333333, 3333333],
        ],
    },
};

export type AttributionFilterOption = "noFilter" | "outstanding" | "committed";

export type Filter = {
    years: number[] | null;
    attribution: AttributionFilterOption | null;
};

const exampleContext = {
    showExampleData: () => console.log("processing"),

    data: null,
    filter: {},
    rawDataReceived: () => {},
    yearsFilterOptions: [2022, 2023, 2024, 2025],
    yearsFilter: [],
    setYearsFilter: (y: number[]) => console.log(`year filter ${y}`),
    dataUploaded: false,
    setDataUploaded: () => {},
    attributionFilterOptions: ["noFilter", "outstanding", "committed"],
    attributionFilter: "noFilter",
    setAttributionFilter: (o: AttributionFilterOption) => console.log(`attributionFilter${0}`),
};

type DataContextType = {
    showExampleData: () => void;
    data: null | Data;
    rawDataReceived: (data: null | RawData) => void;
    yearsFilterOptions: number[];
    yearsFilter: number[];
    setYearsFilter: (year: number[]) => void;
    attributionFilterOptions: string[];
    attributionFilter: string;
    setAttributionFilter: (attribution: AttributionFilterOption) => void;
    dataUploaded: boolean;
    setDataUploaded: (dataUploaded: boolean) => void;
};

export const DataContext = createContext<DataContextType>(exampleContext);

export const DataProvider = ({ children }: PropsWithChildren) => {
    // stores incomming json parsed excelSheet data
    const [objectData, setObjectData] = useState<null | ObjectData>(null);

    // data used by widgets, must be provided in the right formatting
    const [data, setData] = useState<null | Data>(null);

    const [yearsFilter, setYearsFilter] = useState<number[]>([]);
    const [yearsFilterOptions, setYearsFilterOptions] = useState<number[]>([]);
    const [attributionFilter, setAttributionFilter] = useState<AttributionFilterOption>("noFilter");
    const [dataUploaded, setDataUploaded] = useState(false);

    const showExampleData = () => {
        setData(exampleData);
    };

    const rawDataReceived = (rawData: RawData | null) => {
        // make objects
        const newObjects = createObjectData(rawData);
        setObjectData(newObjects);
        // set filter options and value based on years in data
        const uniqueYears = Array.from(new Set(newObjects?.general.map((obj) => obj.year))).filter((num) => num !== undefined) as number[];
        setYearsFilterOptions(uniqueYears);
        setYearsFilter(uniqueYears);
        setAttributionFilter("noFilter");
        // useEffect will apply the filters and set data
    };

    useEffect(() => {
        const filter = {
            years: yearsFilter,
            attribution: attributionFilter,
        };
        const newData = !!objectData ? createTableAndChartData(objectData, filter) : null;
        setData(newData);
    }, [attributionFilter, objectData, yearsFilter]);

    const newValue = {
        data,
        rawDataReceived,
        showExampleData,
        yearsFilterOptions: yearsFilterOptions,
        yearsFilter,
        setYearsFilter,
        attributionFilterOptions: exampleContext.attributionFilterOptions,
        attributionFilter,
        setAttributionFilter,
        dataUploaded,
        setDataUploaded,
    };

    return <DataContext.Provider value={newValue}>{children}</DataContext.Provider>;
};
