import { Accordion, AccordionDetails, AccordionSummary, Container, Divider, List, ListItem, Paper, Typography } from "@mui/material";
import { Changes } from "../utils/ChangeLogConstants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const showBulletList = (list: string[]) => {
    return (
        <List>
            {list.map((item, i) => {
                return <ListItem sx={{ display: "list-item" }} key={i}>{`${item}`}</ListItem>;
            })}
        </List>
    );
};

export const ChangeLog = () => {
    return (
        <Container>
            <Paper elevation={3} sx={{ my: 2, p: 2 }}>
                <Typography variant="h3">{"What's new"}</Typography>
                <Divider flexItem sx={{ mb: 2 }} />
                {Changes.map((change, j) => {
                    return (
                        <Accordion defaultExpanded={j === 0} key={j}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6">{`${change.version} - ${change.date}`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {change.additions && change.additions.length > 0 && (
                                    <>
                                        <Typography variant="h6">{"Added:"}</Typography>
                                        {showBulletList(change.additions)}
                                    </>
                                )}
                                {change.changes && change.changes.length > 0 && (
                                    <>
                                        <Typography variant="h6">{"Changed:"}</Typography>
                                        {showBulletList(change.changes)}
                                    </>
                                )}
                                {change.fixes && change.fixes.length > 0 && (
                                    <>
                                        <Typography variant="h6">{"Fixed:"}</Typography>
                                        {showBulletList(change.fixes)}
                                    </>
                                )}
                                {change.updates && change.updates.length > 0 && (
                                    <>
                                        <Typography variant="h6">{"Updated:"}</Typography>
                                        {showBulletList(change.updates)}
                                    </>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Paper>
        </Container>
    );
};
