import { FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { ChangeEvent, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

export type TableGrouping = "client" | "country" | "activity";

const useStyles = makeStyles((theme) => ({
    smallerRadio: {
        "& .MuiSvgIcon-root": {
            // Adjust the width and height to make the radio buttons smaller
            width: "10px", // Customize the width
            height: "10px", // Customize the height
        },
    },
    label: {
        fontSize: "12px", // Set the label font size to 12px
    },
}));

type Props = { groupingSelectionChanged: (grouping: TableGrouping) => void };

export const SelectTableGrouping = ({ groupingSelectionChanged }: Props) => {
    const { t } = useTranslation();
    const [selectedSection, setSelectedSection] = useState("clients");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newGrouping = event.target.value as TableGrouping;
        setSelectedSection(newGrouping);
        groupingSelectionChanged(newGrouping);
    };

    const classes = useStyles();
    return (
        <FormControl sx={{ width: "400px" }}>
            <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectedSection}
                onChange={handleChange}
            >
                <FormControlLabel
                    value="clients"
                    control={
                        <Radio
                            className={classes.smallerRadio}
                            sx={{
                                color: blueGrey[50],
                                "&.Mui-checked": {
                                    color: blueGrey[100],
                                },
                            }}
                        />
                    }
                    label={<span className={classes.label}>{t("table.filter.options.client")}</span>}
                />
                <FormControlLabel
                    value="country"
                    control={
                        <Radio
                            className={classes.smallerRadio}
                            sx={{
                                color: blueGrey[50],
                                "&.Mui-checked": {
                                    color: blueGrey[100],
                                },
                            }}
                        />
                    }
                    label={<span className={classes.label}>{t("table.filter.options.country")}</span>}
                />
                <FormControlLabel
                    value="activity"
                    control={
                        <Radio
                            className={classes.smallerRadio}
                            sx={{
                                color: blueGrey[50],
                                "&.Mui-checked": {
                                    color: blueGrey[100],
                                },
                            }}
                        />
                    }
                    label={<span className={classes.label}>{t("table.filter.options.activity")}</span>}
                />
            </RadioGroup>
        </FormControl>
    );
};
