import { createContext, PropsWithChildren, useCallback, useState } from "react";
import { AlertColor } from "@mui/material";

type AlertContextType = {
    text: string;
    type: AlertColor;
    setAlert(text: string, type: AlertColor): void;
};

const AlertContext = createContext<AlertContextType>({
    text: "",
    type: "success",
    setAlert: (text: string, type: AlertColor) => {},
});

export const AlertProvider = ({ children }: PropsWithChildren) => {
    const [text, setText] = useState("");
    const [type, setType] = useState<AlertColor>("success");
    const setAlert = useCallback((text: string, type: AlertColor) => {
        setText(text);
        setType(type);
    }, []);

    return (
        <AlertContext.Provider
            value={{
                text,
                type,
                setAlert,
            }}
        >
            {children}
        </AlertContext.Provider>
    );
};

export default AlertContext;
