import { Box, Divider, Stack } from "@mui/material";
import { CustomTableContainer } from "../components/TableContainer";
import { ResultContainer } from "../components/ResultContainer";
import { GhgEmissionsCard } from "../components/GhgEmissionsCard";
import { EmploymentCard } from "../components/EmploymentCard";
import { ValueAddedCard } from "../components/ValueAddedCard";
import { InvestmentData } from "../components/investment/InvestmentData";
import { CustomTable } from "../components/customoverviewtable/CustomTable";
import { dashboardSettings } from "../utils/dashboardSettingsConstants";
import { MenuContext } from "../App";
import { useContext, useEffect } from "react";
import { DataContext } from "../contexts/DataContext";

export const Dashboard = () => {
    const { setSelectedMenu } = useContext(MenuContext);
    const { data } = useContext(DataContext);

    useEffect(() => {
        setSelectedMenu("overview");
    }, [setSelectedMenu]);
    return (
        <Stack>
            <ResultContainer>
                <InvestmentData />
                <Divider flexItem orientation="vertical" sx={{ border: "solid 2px " }} />
                <Box width={dashboardSettings.cardContainer}>
                    <GhgEmissionsCard />
                </Box>
                <Box width={dashboardSettings.cardContainer}>
                    <EmploymentCard />
                </Box>
                <Box width={dashboardSettings.cardContainer}>
                    <ValueAddedCard />
                </Box>
            </ResultContainer>
            <CustomTableContainer>
                <CustomTable dataType="dashboard" tableData={data?.overviewTableData} />
            </CustomTableContainer>
        </Stack>
    );
};
