import { Stack, Typography } from "@mui/material";
import { dashboardSettings } from "../utils/dashboardSettingsConstants";
type Props = {
    bgColor: string;
    value: number | string;
    description: string;
};
export const ResultCard = ({ bgColor, value, description }: Props) => {
    return (
        <Stack
            height="144px"
            width="90%"
            alignItems="center"
            justifyContent="center"
            sx={{ backgroundColor: bgColor, borderRadius: dashboardSettings.borderRadius }}
            position="relative"
        >
            <Typography fontSize="40px" color="#FFFFFF">
                {value}
            </Typography>
            <Typography fontSize="20px" color="#FFFFFF">
                {description}
            </Typography>
        </Stack>
    );
};
