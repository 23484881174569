import { Stack, Typography } from "@mui/material";
import { ResultCardContainer } from "../components/ResultCardContainer";
import { ResultContainer } from "../components/ResultContainer";
import { EmploymentCard } from "../components/EmploymentCard";
import { CustomTableContainer } from "../components/TableContainer";
import { CustomTable } from "../components/customoverviewtable/CustomTable";
import { ResultSummary } from "../components/resultsummary/ResultSummary";
import { chartColors, employmentColors } from "../utils/colorConstants";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DataContext } from "../contexts/DataContext";
import WaterfallChart from "../components/WaterfallChart";
import { MenuContext } from "../App";

export const Jobs = () => {
    const { data } = useContext(DataContext);
    const { setSelectedMenu } = useContext(MenuContext);
    const { t } = useTranslation();

    const devicePixelRatio = window.devicePixelRatio;
    let columnSpacingAccordingRatio = 3;
    if (devicePixelRatio > 1.3) {
        columnSpacingAccordingRatio = 3.8;
    }
    useEffect(() => {
        setSelectedMenu("jobs");
    }, [setSelectedMenu]);

    return (
        data && (
            <Stack>
                <ResultContainer>
                    <ResultCardContainer>
                        <EmploymentCard />
                    </ResultCardContainer>
                    <ResultCardContainer>
                        <Typography fontSize="16px" fontWeight={600}>
                            {t("chart.jobs.individualClientsTitle")}
                        </Typography>
                        <WaterfallChart
                            data={data.employmentIndividualClientsChartData}
                            translationPrefix="chart.jobs"
                            chartColors={chartColors.jobs}
                            overrideColors={{ total: chartColors.jobsTotal }}
                            showLegend={false}
                            rotateXaxis
                            chartTextColor="white"
                            yAxisTitle="Jobs Supported"
                        />
                        <ResultSummary
                            dataType={"employment"}
                            bgColor={employmentColors.tableBg}
                            data={data.employmentIndividualClientsTableData}
                            translationPrefix="chart.jobs"
                            columnSpacing={columnSpacingAccordingRatio}
                            columnRightPadding={4}
                        />
                    </ResultCardContainer>
                    <ResultCardContainer>
                        <Typography fontSize="16px" fontWeight={600}>
                            {t("chart.jobs.sectoralExposureTitle")}
                        </Typography>
                        <WaterfallChart
                            data={data.employmentSectoralExposureChartData}
                            translationPrefix="chart.jobs"
                            chartColors={chartColors.jobs}
                            overrideColors={{ total: chartColors.jobsTotal }}
                            showLegend={false}
                            rotateXaxis={false}
                            chartTextColor="white"
                            yAxisTitle="Jobs Supported"
                        />
                        <ResultSummary
                            dataType={"employment"}
                            bgColor={employmentColors.tableBg}
                            data={data.employmentSectoralExposureTableData}
                            translationPrefix="chart.jobs"
                            columnSpacing={columnSpacingAccordingRatio}
                            columnRightPadding={4}
                        />
                    </ResultCardContainer>
                </ResultContainer>
                <CustomTableContainer>
                    <CustomTable dataType="jobs" tableData={data.employmentTableData} />
                </CustomTableContainer>
            </Stack>
        )
    );
};
