import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import { UploadError } from "../hooks/useUploadFile";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

export const ErrorDialog = ({ error, reset }: { error: UploadError | null; reset: () => void }) => {
    const { t } = useTranslation();

    const stackTrace = t("upload.error.title");
    const message = error?.error;

    const sendMail = `mailto:support@jointimpactmodel.org?subject=Error Report&body=${encodeURIComponent(`Stack Trace:\n${stackTrace}\n\nMessage:\n${message}`)}`;
    if (error) {
        return (
            <Dialog open={true} fullWidth={true} maxWidth="md" onClose={reset}>
                <DialogTitle>{error.data ? t("upload.validation.title") : t("upload.error.title")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {error.error && (
                            <>
                                {t("upload.error.info")}
                                <Link href={sendMail}>Send mail</Link>
                            </>
                        )}
                        {error.data && t("upload.validation.info")}
                    </DialogContentText>
                    {/* Render validation failed */}
                    {error.data && (
                        <List sx={{ pt: 0 }}>
                            {error.data.map((value) => (
                                <ListItem disablePadding>
                                    <ListItemText>{value}</ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    )}
                    {/* Render error in the model */}
                    {error.error && (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{error.msg}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {/* Render the stack trace */}
                                {error.error && <pre>{error.error}</pre>}
                            </AccordionDetails>
                        </Accordion>
                    )}
                </DialogContent>
            </Dialog>
        );
    }
};
