import { Stack } from "@mui/material";
import { t } from "i18next";
import { InvestmentRow } from "./InvestmentRow";
import { DataContext } from "../../contexts/DataContext";
import { useContext } from "react";

export const InvestmentCard = () => {
    const { data } = useContext(DataContext);

    const investmentData = data?.investmentData;

    return (
        investmentData && (
            <Stack direction="column" spacing={4}>
                <InvestmentRow text={t("investmentCard.clients")} value={investmentData.clients} />
                <InvestmentRow text={t("investmentCard.countries")} value={investmentData.countries} />
                {/* the values below are reported as 'in m USD' so convert value to display */}
                <InvestmentRow
                    text={t("investmentCard.totalRevenue")}
                    value={investmentData.totalRevenue ? (investmentData.totalRevenue / 1000000).toFixed(2) : undefined}
                />
                <InvestmentRow
                    text={t("investmentCard.totalOutstanding")}
                    value={investmentData.totalOutstanding ? (investmentData.totalOutstanding / 1000000).toFixed(2) : undefined}
                />
            </Stack>
        )
    );
};
