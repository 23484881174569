import  i18n  from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './assets/languages/en.json';

const url = new URL(document.location.href);
let params = url.searchParams;
const lng = params.get('lang') ?? 'en';


i18n
  .use(initReactI18next) // Initialize react-i18next
  .init({
    fallbackLng: 'en',
    lng: lng,
    resources: {
        en
    }
    
  });

export default i18n;
