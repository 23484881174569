import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
type Props = {
    subHeaderIndex: number;
    cellRefs: any;
    subHeaderColor: string;
    setSortColumn: any;
    subHeader: string;
    columnIndex: number;
    isSortColumn: boolean;
};
export const SubHeader = ({ subHeaderIndex, subHeaderColor, cellRefs, setSortColumn, subHeader, columnIndex, isSortColumn }: Props) => {
    const [ascending, setAscending] = useState<boolean | null>(null);
    const { t } = useTranslation();
    return (
        <Stack
            key={subHeaderIndex}
            ref={(el) => {
                // Store the ref for each cell in the cellRefs array
                const cellRef = { current: el };
                cellRefs.current.push(cellRef);
            }}
            width="100%"
            minHeight="55px"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                backgroundColor: subHeaderColor,
                color: "white",
                cursor: "pointer",
            }}
            p={1}
            py={2}
            onClick={() => {
                // cycle through 3 options when clicking: no sorting (default), ascending, descending
                // if we know we are sorting, check direction and switch to new state
                if (isSortColumn) {
                    if (ascending) {
                        // keep sorting on, move to descending
                        setAscending(false);
                        setSortColumn({ columnIndex: columnIndex, ascending: false });
                    } else {
                        // disable sorting
                        setSortColumn(null);
                    }
                } else {
                    // enable sorting, make sure it is ascending by default
                    setAscending(true);
                    // update parent
                    setSortColumn({ columnIndex: columnIndex, ascending: true });
                }
            }}
        >
            <Typography fontSize="12px">{t(`table.subHeader.${subHeader}`)}</Typography>
            <Stack>
                <ArrowDropUpIcon sx={{ alignSelf: "flex-start", mb: -1.2, color: isSortColumn && ascending ? "black" : "white" }} />
                <ArrowDropDownIcon sx={{ alignSelf: "flex-start", mt: -1.2, color: isSortColumn && !ascending ? "black" : "white" }} />
            </Stack>
        </Stack>
    );
};
