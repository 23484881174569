export type Change = {
    version: string;
    date: string;
    additions?: string[];
    changes?: string[];
    fixes?: string[];
    updates?: string[];
    removed?: string[];
};

export const Changes: Change[] = [
    {
        version: "2024.3.1.8",
        date: "2024-10-18",
        fixes: [
            "- GHG values in web UI will now display properly with 'PCAF global standard' filter selected.",
        ],
    },
    {
        version: "2024.3.1.7",
        date: "2024-09-11",
        additions:   [
            "- An outlier check for 'lower' outliers (i.e. statistical data abnormaly low compared to the rest of the sample) was applied. In this version, only some non-CO2 emission factors are affected, for specific countries and sectors."
        ],
        fixes: [
            "- Relative equity shares inserted in the input template used to be converted into percentages, despite being already provided in percentages by the user. This was removed to ensure the correct numbers were returned by the model.",
            "- In the output template, the 'loan / investment (mUSD)' column was adjusted to actually show the numbers in millions and the calculation of the total sum of this column was removed due to double-counting issues.",
        ],
    },
    {
        version: "2024.3.1.6",
        date: "2024-05-22",
        fixes: [
            "- Updated the NACE sector classification used in the model from the v2.0 to the v2.1 revision.",
            "- Updated the list of sectors categorized as \"fossil fuel sectors\" in the PAI 4 calculation to match the NACE 2.1 revision.",
            "- Rephrased one of the error messages related to the Attribution input tab."
        ],
    },
    {
        version: "2024.3.1.5",
        date: "2024-03-15",
        fixes: ["- Enabled PAI 2 and 3 display in the output file. An issue when merging the data prevented the results from showing up."],
    },
    {
        version: "2024.3.1.4",
        date: "2024-03-15",
        fixes: ["- Enabled PAI 5 display in the output file. An issue with the unique_id prevented the results from showing up."],
    },
    {
        version: "2024.3.1.3",
        date: "2024-03-08",
        additions: [
            "- Created an additional error message when duplicate GTAP sectors are inserted in the same economic activity input field (e.g. “1 2 2 3”).",
            "- Added an error message if data is missing when using the customised breakdown feature.",
            "- The client type is provided in the PAI output file.",
        ],
        fixes: [
            "- Adjusted incorrect ISIC and GICS sector names and codes.",
            "- Fixed an issue with the PAI module preventing specific input combinations from running.",
            "- Full country names are now returned when using the Sector exposure tab.",
            "- Allow rows with identical client names to run if other inputs (e.g. economic activity, country) differ.",
            "- Prevent the model from duplicating output rows for specific input combinations.",
        ],
    },
    {
        version: "2024.3.1.2",
        date: "2024-01-18",
        changes: [
            "- Client data in USD will be converted to EUR in the PAI output file to match the expected units of the SFDR’s indicators. Results in the regular output file will be in the currency specified in the input file.",
        ],
        fixes: [
            "- Inserting regions (i.e. Africa, Asia, World) would result in an error.",
            "- Providing renewable electricity consumption data would result in an error if the model’s estimate for consumed electricity was lower.",
            "- Not inserting any revenue or project value in the input file would make the model crash.",
            "- Fixed discrepancy between PAI attributed scope 1, 2 and 3 results and the regular model’s estimate for attributed scope 1, 2 and 3. The issue was caused by a misuse of the exchange rate.",
            "- Adjusted incorrect NACE, ISIC and GICS sector names and codes.",
        ],
    },
    {
        version: "2023.3.1.1",
        date: "2023-12-15",
        fixes: [
            "- Added quotation marks around misspelled countries in error messages to improve user-friendliness.",
            "- Fixed a formula in the output template which prevented the table from showing power enabling results.",
        ],
    },
    {
        version: "2023.3.1.0",
        date: "2023-12-14",
        additions: [
            "- Added modelling of Potential Adverse Indicators (PAIs) 1 to 6, aligned with SFDR.",
            "- Added quotes (“”) to the error messages around country and economic activity labels to improve user-friendliness.",
            "- Added an error checking whether a client inserted in the Attribution tab also had corresponding data in the Individual clients tab.",
        ],
        changes: [
            "- Returned the full country name and the ISO alpha 3 code in the output file instead of only the code.",
            "- Removed Swaziland from the list of authorised countries as Eswatini was already included.",
            "- Allowed inserting only the relative equity share data for a client in the Attribution tab. Previously, this would return an error.",
        ],
        fixes: [
            "- Fixed a mismatch between the expected unit for “%” input columns and the error messages. The percentages now must be inserted with the % sign.",
            "- Adjusted the spelling of some NACE sectors to align the entire sector list to NACE 2.1.",
            "- Added back some statistic data which was wrongly identified as outliers in the v3.0. This prevented Scope 2 non-CO2 of some countries from appearing in the output file.",
            "- Fixed the calculation of power production, which used to be wrongly set to 0 when estimated from installed capacity.",
            "- Prevented the model from returning negative Scope 3 results when provided and high Scope 2 data would be provided. The model now sets the negative values to 0.",
            "- Prevented the model from crashing after attempting to run an input file with an error and then trying to run a functioning file.",
        ],
        updates: [],
    },
    {
        version: "2023.3.0.4",
        date: "2023-11-22",
        additions: [],
        changes: [],
        fixes: ["- Fixed bug where filling in committed and outstanding in the Attribution resulted in no attribution output"],
        updates: [],
    },
    {
        version: "2023.3.0.3",
        date: "2023-11-17",
        additions: [],
        changes: [],
        fixes: ["- Fixed bug where filling in committed and outstanding in the Attribution resulted in too many rows"],
        updates: [],
    },
    {
        version: "2023.3.0.2",
        date: "2023-11-16",
        additions: [],
        changes: [],
        fixes: ["- Fix disappearing client names.", "- Fix customized breakdown not working."],
        updates: [],
    },
    {
        version: "2023.3.0.1",
        date: "2023-11-02",
        additions: [],
        changes: [],
        fixes: [
            "- Invalid sectors and countries issues solved, now returning a clear error.",
            "- Using currency other than euro (i.e USD) doesn't prevent the model from running anymore.",
        ],
        updates: [],
    },
    {
        version: "2023.3.0.0",
        date: "2023-10-31",
        additions: [
            "- Breakdown of the results the electricity sector using GTAP11A Power.",
            "- Added the estimation of female employment from power enabling projects.",
            "- Sector mapping between ISIC rev.4 level 2, 3 and 4 to GTAP sector, and between GICS 6-digits to GTAP sectors.",
            "- Added more input validation checks which would return a clear error message.",
            "- Added two tabs to the output template: an introduction tab, and a summary table, providing a bird’s view of the results.",
            "- Added a “Project timeline” input field, to annualize results when interested in the ex-ante approach.",
            "- For GHG results, the model now returns a PCAF data quality score depending on the data provided by the user.",
            "- Added PCAF data quality scores columns in the input template.",
            "- Added an “Attributed Total - Outstanding” in the output template, corresponding to Total multiplied by the outstanding attribution share.",
            "- Added an “Economic activity - Code” column to the output template, returning the sector code corresponding to the sector name inserted.",
        ],
        changes: [
            "- Updated the Social Accounting Matrices’ and GHG intensities’ base year to 2017 using GTAP11A Power (includes addition of 20 new countries in the JIM coverage).",
            "- Updated the Asset-Turnover ratios using 2017 GTAP11A Power data and the latest World Bank data available.",
            "- Enabled the model to use 2020 to 2025 employment intensities using ILO data and forecasted output data.",
            "- Updated the net capacity factors and energy demand data using EIA and IEA data.",
            "- Updated the EUR-USD exchange rate to 1.0884, available on August 1st 2023.",
            "- Improved the coverage of the employment intensities to includes “Rest of” regions for which GTAP data is available.",
            "- Handled outliers in GHG and employment intensities for various sectors and countries by using the corresponding subregional data.",
            "- Changed the regional mapping to separate China from Asia.",
            "- When running the power module for a country lacking energy demand data, the model will now return “n/a” instead of zeroes (0).",
            "- Clarified the existing input validation error messages (e.g. wrong country inserted).",
            "- On top of regular sector names, the sector codes can now be inserted too (e.g. “NACE-A” instead of “Agriculture, forestry and fishing”).",
            "- Renamed the input template tabs from “Client input” and “FI and PE” to “Individual clients” and “Sector exposure”.",
            "- “Installed capacity” and “Reporting year” are now nice-to-have input fields.",
            "- Renamed the input field “Investee name” to “Investee name/code” in the input template.",
        ],
        fixes: [
            "- Fixed the use of the “Absolute emissions - Scope 3” input field, for which the provided data used to be incorrectly allocated between CO2 and non-CO2.",
            "- Miscalculation of Absolute emissions Scope 3 - Imports results when the inputted sector would be mapped to multiple GTAP sectors.",
        ],
        updates: [],
    },
    {
        version: "2023.2.0.8",
        date: "2023-6-14",
        additions: [],
        changes: [],
        fixes: ["- Mapped the 'Freshwater aquaculture' NACE sector to GTAP's sector 14. It was previously incorrectly linked to GTAP sector 15."],
        updates: [],
    },
    {
        version: "2023.2.0.7",
        date: "2023-5-10",
        additions: [],
        changes: [
            "- In the Output template, the power enabled GHG results, previously labelled “Absolute emissions Scope 3 Local - CO2/non-CO2”, are now labelled “Absolute emissions Other - CO2/non-CO2”.",
        ],
        fixes: [
            '- In the Client Input tab, if 1) the selected client type was "Project finance construction phase" and 2) "EBITDA" was provided, the model would mistakely triple the project value figure used for the modeling. Fixing this issue results in a decrease of direct,supply chain and induced estimated results for the scenario above-mentioned.',
        ],
        updates: [],
    },
    {
        version: "2023.2.0.6",
        date: "2023-3-8",
        additions: [],
        changes: [],
        fixes: [
            "- The attribution share of a financial institution's investee is now calculated as the FI's attribution share (of the user in the FI) times the investee's attribution share (of the FI in the investee).",
            "- Prevent the model from crashing if inputs are inserted outside of the predefined columns.",
        ],
        updates: [],
    },
    {
        version: "2022.2.0.4",
        date: "2022-12-1",
        additions: [],
        changes: [],
        fixes: ["- Prevents the model from crashing if, in the attribution sheet, the facility number is filled in."],
        updates: [],
    },
    {
        version: "2022.2.0.3",
        date: "2022-4-1",
        additions: [],
        changes: [],
        fixes: [
            '- When "Payments to suppliers and organisations: Total/Local" is not provided, changed which modelled figure is used by the model when quantifying supply chain and induced impact. While the JIM uses country and sector statistics to estimate this missing data point, an additional adjustment may be required if the sum of the direct value added and total procurements does not align with the revenue/project value provided. Instead of using the adjusted value, the model was running the non-adjusted statistical estimate in the IO model.',
            "- Prevent the model from crashing if, in the Attribution sheet, one of the sections (outstanding/committed) is used while the other is left empty.",
        ],
        updates: [],
    },
    {
        version: "2022.2.0.2",
        date: "2022-2-14",
        additions: ["- In the User Interface, error messages appear if data is missing in the Attribution sheet."],
        changes: [],
        fixes: [
            '- Removed bug which prevented the model from running if the "Committed" columns of the Attribution sheet were used but not the "Outstanding" ones.',
        ],
        updates: [],
    },
    {
        version: "2021.2.0.1",
        date: "2021-12-24",
        additions: [],
        changes: [
            "- In the Output file, the “Attribution - Oustanding/Committed amount” of unlisted clients now includes the estimation from the relative equity share times the total equity, while it was excluded before.",
        ],
        fixes: [
            '- Enable calculation of attribution share if only “relative equity share” and “outstanding/committed amount - debt", and the latter is set to 0 (zero).',
            "- Remove duplicate output lines from the Output file when multiple lines for the same client are inserted in the Attribution sheet of the Input Template.",
            "- Include missing new input columns from the Attribution sheet when applying exchange rates if the data is inserted in EUR (euro).",
        ],
        updates: [],
    },
    {
        version: "2021.2.0.0",
        date: "2021-11-29",
        additions: [],
        changes: [
            "- Modification of terms and descriptions in the all documents (e.g. User Guide, Input Template, Output file) to align with PCAF’s methodology",
            "- Updated the non-CO2 data from GTAP used for the GHG intensities to the latest available",
            "- Excluded the non-production related non-CO2 data from GTAP",
            "- Alignment of the attribution calculation steps with PCAF’s methodology",
            "- Alignment of the “Attribution” sheet’s input fields with PCAF’s methodology",
            "- In the finance enabling module, replacement of a fixed marginal asset turnover ratio by average asset turnover ratios per sector and region",
        ],
        fixes: ["- Adjustment of the impact multipliers’ calculation to represent the attributed impact per outstanding USD"],
        updates: [],
    },
    {
        version: "2021.1.6.0",
        date: "2021-07-29",
        additions: [
            "- Calculated 2019 employment statistics (e.g. formal sector share, employment intensities).",
            "- In the UI, provide breakdown of sub-indicators (gender, co2/non-co2, wages/etc.) in the ‘Results’ table;",
            "- In the UI, provide breakdown of sub-scopes (direct, supply chain, induced) in the ‘Results’ table for finance enabling impact;",
            "- In the UI, display the total capital outstanding in the ‘Investment data’ table;",
            "- In the UI, display the power production in the ‘Investment data’ table;",
            "- In the UI, provide a short definition of the confidence level of results at the bottom of the ‘Results’ table;",
            "- In the Output file, display impact multipliers per mUSD of sales and per mUSD of capital outstanding in two new columns (e.g. ‘jobs / mUSD - of sales’ and ‘jobs / mUSD of capital’);",
            "- In the Output file, display FIs’ capital outstanding from the finance enabling module in a column next to the ‘Sales’ column.",
        ],
        changes: [
            "- Enabled modelled estimation of direct jobs for clients in the Client input sheet;",
            "- Enabled the model to use either 2018 or 2019 statistics (e.g. EIs), depending on the inputted fiscal year;",
            "- Updated energy consumption data from IEA/EIA to 2018 (i.e. latest available year);",
            "- In the User Interface, in the ‘Comparison’ page, ‘Sel 1’ and/or ‘Sel 2’ are replaced by the client name if a specific client name is selected in the filters;",
            "- In the UI, the available filtering options (e.g. country, economic activity) will adjust to the selected ‘Attribution’ filter, if necessary;",
            "- In the Input Template, moved the ‘Direct employment’ columns from required inputs to optional inputs, as impact can now be estimated.",
        ],
        fixes: [
            "- Used data from v1.5 update to calculate female share employment 2018 instead of older non-revised data;",
            "- Adjustment of the regional 2018 formal sector share and adjustment.",
            "- Prevent duplication of results when inserting multiple attribution lines for the same client.",
        ],
        updates: [],
    },
    {
        version: "2021.1.5.1",
        date: "2021-06-14",
        additions: [],
        changes: ["- The time limit for automatic log out of an inactive session has been extended"],
        fixes: [
            "- Negative estimates for direct wages and total procurement when using the EBITDA feature are prevented and replaced by zeros (0)",
            "- Fixed bug preventing the model from running if the Attribution input sheet was used",
        ],
        updates: [],
    },
    {
        version: "2021.1.5.0",
        date: "2021-05-03",
        additions: [
            "- ‘EBITDA’ was added to the ‘Client Input’ sheet as an optional input field;",
            "- ‘Greenhouse gas emissions: indirect – scope 3’ was added to the Client Input sheet as an optional input field;",
            "- ‘Comment’ was added to the ‘Client Input’ and ‘FI and PE’ sheets as an optional input field. Its content is displayed in the Excel Output file;",
            "- A country classification ‘least developed countries’ (LDC) is provided in the Excel Output file, depending on the inputted country;",
            "- An LDC filtering option is available in the User Interface;",
            "- The attribution amount of the client is now displayed in the Excel Output file.",
        ],
        changes: [
            "- The input file sheets ‘Example_1’, ‘Example_2 and ‘Example_3’ have been respectively renamed ‘Client input’, ‘FI and PE’ and ‘Attribution’;",
            "- Updated the GTAP data the version 10 to 10A;",
            "- Updated the World Bank growth data to the latest available as of April 16th 2021;",
            "- Updated the ILO employment data to the November 2020 estimates;",
            "- The model automatically allocates the remainder of the project value to ‘miscellaneous’ if the distribution of project value over sectors in the customized sector breakdown does not add up to 100%;",
            "- The required economic activity input to enable the customised breakdown feature has been changed ‘Customised breakdown’ (instead of ‘Various – customised breakdown’);",
            "- In the ‘FI and PE’ sheet, users can provided a detailed breakdown of the capital outstanding per size of enterprise;",
            "- In the ‘Attribution’ sheet, the capital and assets data will be prioritised over the equity share if all these inputs are provided;",
            "- In the ‘Attribution’ sheet, the ‘Financial instrument’ field has been removed;",
            "- In the User Interface, filtering can now be done based on multiple parameters.",
        ],
        fixes: [
            "- Overestimation of ‘GHG scope 3 – import’ due to miscalculation of import procurement;",
            "- Adjusted estimation of ‘GHG scope 3’ to use estimate scope 2 emissions, even if provided by the user;",
            "- Adjusted formal/informal employment shares;",
            "- Prevented the model from unnecessarily expecting a ‘Power technology type’ input when the power enabling feature is used.",
        ],
        updates: [],
    },
    {
        version: "2021.1.4.1",
        date: "2021-02-12",
        additions: [],
        changes: [],
        fixes: [
            "- Power enabling impact is not quantified anymore if a country’s individual IO table is not available, due to overestimated results otherwise",
        ],
        updates: [],
    },
    {
        version: "2021.1.4.0",
        date: "2021-01-22",
        additions: [
            "- Enabled the split of the sales/project value across multiple economic activities thought the ‘customised breakdown’ feature in the Example_1 sheet",
            "- Added a ‘Reporting year’ filter in the UI",
        ],
        changes: [
            "- Disabled the calculation of import-related GHG emissions for the ‘World’ region",
            "- Flagged regions for which formal/informal jobs breakdown is not available by displaying ‘n/a’ in the Output file’s results",
            "- Renamed the ‘CO2 Emitted Scope 3’ result indicator into ‘CO2 Emitted Scope 3 – Local’, to clarify the distinction with ‘CO2 Emitted Scope 3 – Imports’ (same for non-CO2)",
            "- Modified the ‘Customised breakdown’ column headers in the input file. The Input Template’s latest version must be used",
            "- Removed previous change made to percentage input fields of the Input Template (v2020.1.3.2). Values can now be inserted in percentages in those columns",
            "- Changed the available inputs of Size of Enterprise in the Example_2 sheet, and merged ‘Micro enterprise’ and ‘SME’ into ‘Micro/SME’",
        ],
        fixes: [
            "- Confidence levels calculation is aligned with the methodology documents and displayed for all results",
            "- In the finance enabling module, firm size adjustments were incorrect. The micro and SME ratios are now the same (1.36) and the ratio for large enterprise was changed (0.82)",
        ],
        updates: [],
    },
    {
        version: "2020.1.3.2",
        date: "2020-11-24",
        additions: [],
        changes: [
            "- Renamed ´CO2/non-CO2 Emitted Scope 3´ into ´CO2/non-CO2 Emitted scope 3 - Local’ in the Results file",
            "- Turned percentage input fields (i.e. ‘net capacity factor’ and ‘equity share’) into whole number input field. E.g. ‘45%´ equity share should be entered as ‘45’",
        ],
        fixes: [],
        updates: [],
    },
    {
        version: "2020.1.3.1",
        date: "2020-11-16",
        additions: ["- Downloadable documentation made available on the UI"],
        changes: ["- Equity share feature enabled in the attribution module", "- License agreement updated for the official jim release"],
        fixes: [
            "- Estimation of negative direct wages and total procurement prevented",
            "- Calculation of payroll tax, which was not added back to inputted taxes, fixed",
            "- Regions and countries for which formal/informal data is not available will now display ‘n/a’",
        ],
        updates: [],
    },
    {
        version: "2020.1.3.0",
        date: "2020-11-05",
        additions: [
            "- Included imports in the calculation of estimated scope 3 GHG emissions",
            "- Added JIM logo and favicon to the User Interface",
            "- Added acceptance box for Terms and Conditions in the JIM upload page",
            "- Clicking on the JIM logo redirects to the home page",
            "- Added formal and informal employment breakdown to the Output file",
        ],
        changes: [
            "-     Merged the ‘GHG CO2 (t CO2 eq)’ and ‘GHG NON CO2 (t CO2 eq)’ labels of the User Interface into ‘GHG (t CO2 eq)’",
            "- Estimated scope 2 GHG emissions are set to 0 if the power technology type inserted is renewable, except for construction projects",
            "- Allocated scope 1 and scope 2 GHG inputs between CO2 and non-CO2 using country and sector averages",
        ],
        fixes: ["- Fixed gap in induced results related to value added statistics of the GTAP’s dwelling sector"],
        updates: [],
    },
    {
        version: "2020.1.2.1",
        date: "2020-09-18",
        additions: [],
        changes: [],
        fixes: [
            "- Fixed power enabling effect, allowing countries without national GTAP data to use regional data instead",
            "- Added error message if high income countries are present in the input file",
        ],
        updates: [],
    },
    {
        version: "2020.1.2.0",
        date: "2020-07-31",
        additions: ["- Display errors messages in the JIM file loading page when there are input errors in the Input file"],
        changes: [
            "- Changed the format of the Results file generated, from .csv to .xls",
            "- Separated ‘General’, ‘Employment’, ‘GHG’ and ‘Value added’ data into different sheets in the Results file",
        ],
        fixes: [
            "- Fixed power enabling effect, allowing countries without national GTAP data to use regional data instead",
            "- Fixed an error duplicating finance enabling results in the Results file",
        ],
        updates: [],
    },
    {
        version: "2020.1.1.2",
        date: "2020-07-09",
        additions: [
            "- The results displayed when the User Interface loads for the first time now corresponds to the first available year, instead of a sum of all years",
            "- ‘Year’ in the Investment Data section of the User Interface has been renamed ‘Fiscal Year’",
        ],
        changes: [],
        fixes: [
            "- Fixed attribution issue which prevented the model from running if the committed section was empty and the outstanding one filled up",
            "- Fixed an input issue causing the Size of Enterprise input, in the Finance enabling sheet, to be ignored",
            "- Fixed confidence levels in the output file, which was not printed for all results",
            "- Fixed calculation of Sales in the User Interface",
        ],
        updates: [],
    },
    {
        version: "2020.1.1.1",
        date: "2020-06-19",
        additions: [],
        changes: [],
        fixes: ["- Fixed currency conversion not working on sales output column"],
        updates: [],
    },
    {
        version: "2020.1.1.0",
        date: "2020-06-15",
        additions: [
            "- Added Non-CO2 emissions calculation (including for power-enabling and finance-enabling)",
            "- Enabled Panama, Chile, Uruguay in the model",
            "- Enabled Power-enabling effects calculation for regions",
            "- Added Power production to the Results file",
            "- Added, in the Sign Up User Interface page, Licence Agreement acknowledgement sentence and link to the License Agreement",
            "- Added a General line per client in the Results file. Those General lines display the corresponding client’s sales and power production",
            "- Added lists of inputs in the Input Template",
        ],
        changes: [
            "- Splitted GHG (t CO2 eq) in the User Interface into CO2 and Non-CO2 to reflect the implementation of Non-CO2 emissions in the model",
            "- Recalculated regions for CO2 intensities",
            "- Removed Sales and Power production values from the Results file, except in the General lines",
            "- Renamed the Results file headers to match the Methodology and User Guide documents",
            "- Renamed the GHG Sub-indicators in the Results file to reflect the split between CO2 and Non-CO2 emissions",
            "- Renamed the CO2 and Non-CO2 Sub-indicators in the Results file to better relate to the GHG protocol’s scopes",
            "- Splitted Scope 2 and 3 GHG emissions for finance-enabling",
            "- Moved the Total column of the Results file before the sector breakdown",
            "- Added Reporting Year in the Input file and Results file",
        ],
        fixes: [
            "- Fixed attribution issue related to capital mobilized",
            "- Fixed attribution double-counting issues",
            "- Fixed attribution calculation for Project finance construction phase",
            "- Fixed Finance-enabling effect to allow use of regional data when country statistics are not available",
        ],
        updates: [],
    },
    {
        version: "2020.1.0.10",
        date: "2020-05-22",
        additions: ["- Option to use fixed output multiplier in energy calculations.", "- Added option to apply constant ratio to FI capital distribution."],
        changes: [],
        fixes: ["- Solved name clash in confidence levels."],
        updates: [],
    },
    {
        version: "2020.1.0.9",
        date: "2020-04-23",
        additions: [],
        changes: [],
        fixes: [
            "- When providing a custom breakdown direct inputs would be passed as is instead of being distributed among the provided sectors, resulting in overestimates by a factor of 6.",
        ],
        updates: [],
    },
    {
        version: "2020.1.0.8",
        date: "2020-04-21",
        additions: [],
        changes: [],
        fixes: ["- Fixed error which caused Power enabling results not to be merged correctly."],
        updates: [],
    },
    {
        version: "2020.1.0.7",
        date: "2020-04-17",
        additions: [],
        changes: [],
        fixes: [
            "- Empty cells would be filled with 'nan' string when reading DataFrames from Excel input",
            "- Construction project would be treated as clients when providing custom breakdown of activities",
        ],
        updates: [],
    },
    {
        version: "2020.1.0.6",
        date: "2020-04-16",
        additions: [],
        changes: [],
        fixes: [
            "- Improved handling of 0 values in attribution module (avoids division by 0)",
            "- Attribution merging: None would be converted to str when reading data.",
        ],
        updates: [],
    },
    { version: "2020.1.0.5", date: "2020-04-14", additions: [], changes: [], fixes: ['- Fixed error with "Various - customised breakdown"'], updates: [] },
    {
        version: "2020.1.0.4",
        date: "2020-04-09",
        additions: ["-     Throws error if both clients and FI are empty or are completely out of scope (e.g. only high income countries)"],
        changes: ['-     "Duplicate columns" error is more specific and explains which input has duplicate columns'],
        fixes: [
            "- Fixed validation of labels and labeling error checking. Economic activity would be wrongly mapped if GTAP sectors manually inputted.",
            "- Fixed ISO3166 country codes in input would stop model.",
        ],
        updates: [],
    },
    {
        version: "2020.1.0.3",
        date: "2020-04-08",
        additions: [],
        changes: ["- Updated income classification mapping."],
        fixes: ["- Attribution would stop running if not all investments had attribution data."],
        updates: [],
    },
    {
        version: "2020.1.0.2",
        date: "2020-04-08",
        additions: [],
        changes: [],
        fixes: [
            "- Fixed youth employment share for custom breakdown of projects, would add up to more than 100%",
            "- Fixed attribution for investees of funds, would apply investee level attribution twice instead of using fund level attribution.",
        ],
        updates: [],
    },
    { version: "2020.1.0.1 ", date: "2020-04-06", additions: [], changes: [], fixes: ["- Fixed KeyError for formal sector adjustment WORLD."], updates: [] },
    {
        version: "2020.1.0.0",
        date: "2020-04-03",
        additions: ['- Added confidence level calculations in column "Confidence level".'],
        changes: [
            '- Attribution share is now outputted for both committed and outstanding approach. Columns "Attribution method" and "Attribution share" are replaced by "Attribution share - Committed" and "Attribution share - Outstanding".',
            '- "Sub-region Name" column now includes intermediate subregions from UN methodology for more detailed labeling.',
            "- Disabled high income countries and selected regions (Europe, Americas, Northern America, Eastern Europe, Northern Europe, Southern Europe, Western Europe, Oceania, Australia and New Zealand).",
            '- Changed "Backward going concern" to "Backward Temporary" and "Backward Permanent" depending on whether calculations are based on project value or sales',
            "- Changed formal sector adjustments for FIs.",
        ],
        fixes: [],
        updates: [],
    },
    { version: "2020.0.2.3", date: "2020-03-23", additions: ["- Added Miscellaneous - Renewable to green tech types."], changes: [], fixes: [], updates: [] },
    {
        version: "2020.0.2.2",
        date: "2020-03-16",
        additions: ["- Added youth employment breakdown.", "- Added female employment breakdown to supply chain and induced results."],
        changes: [
            "- GHG direct are not set to 0 for project construction phase.",
            "- GHG scope 3 now always uses estimated GHG scope 2 for calculations.",
            "- Power enabling effects in 46 ely are always 0.",
            "- Client types for FIs are filled in from client information.",
        ],
        fixes: ["- Fixed merging error when calculating attribution for clients"],
        updates: [],
    },
    {
        version: "2020.0.2.1",
        date: "2020-03-09",
        additions: ["- Added WORLD CO2 emissions."],
        changes: [],
        fixes: [
            "- Fixed handling of missing columns in construction module.",
            "- Fixed error when no power production in inputs.",
            "- Fixed error when no female employment ratios available.",
            "- Economic activity column allows for integers (i.e. direct GTAP sectors) and may need to be converted to str dtype.",
        ],
        updates: [],
    },
    {
        version: "2020.0.2.0",
        date: "2020-02-28",
        additions: [
            "- Implemented attribution module.",
            "- Added option to convert currencies.",
            "- Added power tech type to FI module.",
            "- Model now runs power module on funds.",
            "- Adjusts estimates for COGS, wages, savings, and taxes if their sum is bigger/smaller than sales.",
            "- Can now input multiple sectors (custom breakdown) to project value lines",
        ],
        changes: [
            "- Power module now uses IEA data instead of WorldBank data for domestic energy demand, when not available JIM falls back on EIA data.",
            "- Updated net capacity factors to 2018.",
            "- Changed net capacity factors",
            '- Can now input GTAP sectors in "Economic activity".',
            "- Can now input ISO codes in country field.",
            "- Company size/type adjustments and power tech type are case insensitive",
            "- Input template now has extra row with units to input",
        ],
        fixes: [
            "- Energy modules correctly handles countries with no multipliers",
            "- Model would stop if no non-construction clients were not in the input",
            '- power enabling not running for construction investments and for rows with not "Power production".',
        ],
        updates: [],
    },
    { version: "2019.0.4.0", date: "2020-01-20", additions: [], changes: ["- Updated CO2 intensities."], fixes: [], updates: [] },
    {
        version: "2019.0.3.0",
        date: "2019-12-20",
        additions: [
            "- Implemented power module.",
            "- Web API returns Excel file highlighting errors in input.",
            "- Added IO tables updated to 2018 using GDP growth from WB data.",
        ],
        changes: [
            "- Use 2018 tables for energy module.",
            "- Changed order of index columns of output with better hierarchy.",
            "- Made sector names case insensitive.",
            "- Made country names case insensitive.",
            "- Updated formal sector and marginal CO adjustments.",
            "- Updated FI module values for Eastern Africa.",
            "- Updated EOR for regions.",
            "- Updated EOR for NGA.",
            "- Updated IRN and VEN io tables",
        ],
        fixes: [
            "- Fix country lookup for regions.",
            "- Fix GHG calculations for direct values using emissions rather than intensities.",
            "- Fix allocation of direct value added to sectors.",
            "- Fix double counting industry sectors.",
            "- Fix calculation of CO2 scope 2",
        ],
        removed: ["- Eliminated primary/secondary distinction of countries/economic activities"],
        updates: [],
    },
];
