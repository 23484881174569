export const emissionsColors = {
    title: "#407243",
    cardBg: "#2A4C2D",
    tableBg: "#407243",
    header: "#2A4C2D",
    subheader: "#407243",
    connected: "#2A4C2D",
};
export const employmentColors = {
    title: "#96212D",
    cardBg: "#64161E",
    tableBg: "#96212D",
    header: "#64161E",
    subheader: "#96212D",
    connected: "#64161E",
};
export const chartColors = {
    ghg: ["#96C499", "#B9D8BB"],
    jobs: ["#ECA8AF"],
    jobsTotal: ["#E27C87"],
    valueAdded: ["#FCCF8B", "#FDDF81", "#FEEFD8"],
};
export const valueAddedColors = {
    title: "#E38B06",
    cardBg: "#985D04",
    tableBg: "#E38B06",
    header: "#985D04",
    subheader: "#E38B06",
    connected: "#985D04",
};
export const investmentColors = {
    title: "#18405B",
};

export const filterColors = {
    header: "#235E84",
    subheader: "#18405B",
};
