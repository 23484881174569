import { Checkbox, FormControl, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataContext } from "../contexts/DataContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const SideBarYearCard = () => {
    const { t } = useTranslation();
    const { yearsFilterOptions, setYearsFilter, yearsFilter } = useContext(DataContext);
    const [yearsSelected, setYearsSelected] = useState<number[]>(yearsFilterOptions);
    const [allSelected, setSelectAll] = useState(true);
    const handleSelectAll = () => {
        setYearsSelected(yearsFilterOptions);
        setSelectAll(true);
        setYearsFilter(yearsFilterOptions);
    };

    const handleSelectFirst = () => {
        const firstYear = yearsFilterOptions[0] || 0;
        setYearsSelected([firstYear]);
        setSelectAll(false);
        setYearsFilter([firstYear]);
    };
    const handleChange = (event: SelectChangeEvent<typeof yearsSelected>) => {
        const {
            target: { value },
        } = event;

        // On autofill, we get a stringified value.
        const stringedYears = typeof value === "string" ? value.split(",") : value;
        const numberedYears = stringedYears.map((year) => Number(year));
        setYearsSelected(numberedYears);
        setYearsFilter(numberedYears);
        setSelectAll(numberedYears.length === yearsFilterOptions.length);
    };

    return (
        <Stack overflow="auto">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography fontSize="16px"> {t("sideBar.year")}</Typography>
                <Typography onClick={allSelected ? handleSelectFirst : handleSelectAll} sx={{ cursor: "pointer" }} variant="caption">
                    {allSelected ? t("sideBar.selectFirst") : t("sideBar.selectAll")}
                </Typography>
            </Stack>
            <FormControl sx={{ m: 1, width: 300 }} size="small">
                <Select
                    id="demo-multiple-checkbox"
                    multiple
                    value={yearsSelected}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                >
                    {yearsFilterOptions.map((year) => (
                        <MenuItem key={year} value={year}>
                            <Checkbox checked={yearsFilter.includes(year)} />
                            <ListItemText primary={year.toString()} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    );
};
