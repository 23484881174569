import { useContext, useEffect } from "react";
import useAlert from "../hooks/useAlert";
import { DataContext } from "../contexts/DataContext";

export const GetExcelOutputNotification = () => {
    const { setAlert } = useAlert();
    const { dataUploaded } = useContext(DataContext);
    useEffect(() => {
        if (dataUploaded) {
            setAlert("The Excel data output can be found in your “Downloads” folder.", "info");
        }
    }, [setAlert, dataUploaded]);
    return <></>;
};
