import { Stack } from "@mui/material";
import { CardTitle } from "./Cardtitle";
import { ResultCard } from "./ResultCard";
import { ResultSummary } from "./resultsummary/ResultSummary";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DataContext } from "../contexts/DataContext";
import { emissionsColors } from "../utils/colorConstants";
import { resultCardSettings } from "../utils/dashboardSettingsConstants";

export const GhgEmissionsCard = () => {
    const { t } = useTranslation();
    const { data } = useContext(DataContext);
    const ghgEmissionsSummary = data?.ghgSummary;
    return (
        ghgEmissionsSummary && (
            //@ts-ignore
            <Stack {...resultCardSettings}>
                <CardTitle tColor={emissionsColors.title} text={t("ghgEmissionsCard.cardTitle")} />
                <ResultCard
                    bgColor={emissionsColors.cardBg}
                    value={ghgEmissionsSummary?.columnTotals[0] ? t("intlNumber", { val: ghgEmissionsSummary?.columnTotals[0].toFixed() }) : "n/a"}
                    description={t("ghgEmissionsCard.resultCard.description")}
                />
                <ResultSummary
                    bgColor={emissionsColors.tableBg}
                    data={ghgEmissionsSummary}
                    translationPrefix="ghgEmissionsCard.resultSummary"
                    columnSpacing={7}
                    columnRightPadding={0}
                    dataType="emissions"
                    isDashboard
                    asteriskText={t("ghgEmissionsCard.asteriskText")}
                />
            </Stack>
        )
    );
};
