export const SubIndicator = "Sub-indicator";
export const Scope = "Scope";
export const SubScope = "Sub-scope";
export const Indicator = "Impact indicator";
export const LDC = "Least Developed Countries (LDC)";
export const Income = "Income classification";
export const Region = "Region Name";
export const SubRegion = "Sub-region Name";
export const Country = "Country";
export const EconAct = "Economic activity";
export const EconActCode = "Economic activity - Code";
export const ReportingYear = "Reporting year";
export const FiscalYear = "Fiscal year";
export const ProjectTimeline = "ProjectTimeline";
export const Client = "Client name/code";
export const ClientType = "Client type";
export const Investee = "Investee name/code";
export const Comment = "Comment";
export const Revenue = "Revenue";
export const OutstandingAmount = "Outstanding amount";
export const PowerProduction = "Power Production";
export const AttributedTotal = "Attributed total - Outstanding";
export const Total = "Total";
export const YouthEmployment = "Youth employment share";
export const ConfidenceLevel = "Confidence level";
export const ImpactOutstanding = "Impact / mUSD outstanding amount";
export const ImpactRevenue = "Impact / mUSD revenue";
export const Sector1 = "1 Paddy rice";
export const Sector2 = "2 Wheat";
export const Sector3 = "3 Cereal grains nec";
export const Sector4 = "4 Vegetables, fruit, nuts";
export const Sector5 = "5 Oil seeds";
export const Sector6 = "6 Sugar cane, sugar beet";
export const Sector7 = "7 Plant-based fibers";
export const Sector8 = "8 Crops nec";
export const Sector9 = "9 Bovine cattle, sheep and goats, horses";
export const Sector10 = "10 Animal products nec";
export const Sector11 = "11 Raw milk";
export const Sector12 = "12 Wool, silk-worm cocoons";
export const Sector13 = "13 Forestry";
export const Sector14 = "14 Fishing";
export const Sector15 = "15 Coal Mining";
export const Sector16 = "16 Crude oil";
export const Sector17 = "17 Natural gas extraction";
export const Sector18 = "18 Minerals nec";
export const Sector19 = "19 Bovine meat products";
export const Sector20 = "20 Meat products nec";
export const Sector21 = "21 Vegetable oils and fats";
export const Sector22 = "22 Dairy products";
export const Sector23 = "23 Processed rice";
export const Sector24 = "24 Sugar";
export const Sector25 = "25 Food products nec";
export const Sector26 = "26 Beverages and tobacco products";
export const Sector27 = "27 Textiles";
export const Sector28 = "28 Wearing apparel";
export const Sector29 = "29 Leather products";
export const Sector30 = "30 Wood products";
export const Sector31 = "31 Paper products, publishing";
export const Sector32 = "32 Refined oil products";
export const Sector33 = "33 Chemical products";
export const Sector34 = "34 Basic pharmaceutical products";
export const Sector35 = "35 Rubber and plastic products";
export const Sector36 = "36 Mineral products nec";
export const Sector37 = "37 Ferrous metals";
export const Sector38 = "38 Energy intensive industries";
export const Sector39 = "39 Metal products";
export const Sector40 = "40 Computer, electronic and optical products";
export const Sector41 = "41 Electrical equipment";
export const Sector42 = "42 Machinery and equipment nec";
export const Sector43 = "43 Motor vehicles and parts";
export const Sector44 = "44 Transport equipment nec";
export const Sector45 = "45 Other industries";
export const Sector46 = "46 Electricity: Transmission and Distribution";
export const Sector47 = "47 Nuclear base load";
export const Sector48 = "48 Coal base load";
export const Sector49 = "49 Gas base load";
export const Sector50 = "50 Wind base load";
export const Sector51 = "51 Hydro base load";
export const Sector52 = "52 Oil base load";
export const Sector53 = "53 Other base load";
export const Sector54 = "54 Gas peak load";
export const Sector55 = "55 Hydro peak load";
export const Sector56 = "56 Oil peak load";
export const Sector57 = "57 Solar peak load";
export const Sector58 = "58 Gas manufacture, distribution";
export const Sector59 = "59 Water";
export const Sector60 = "60 Construction";
export const Sector61 = "61 Trade";
export const Sector62 = "62 Accommodation, Food and service activities";
export const Sector63 = "63 Transport nec";
export const Sector64 = "64 Water transport";
export const Sector65 = "65 Air transport";
export const Sector66 = "66 Warehousing and support activities";
export const Sector67 = "67 Communication";
export const Sector68 = "68 Financial services nec";
export const Sector69 = "69 Insurance";
export const Sector70 = "70 Real estate activities";
export const Sector71 = "71 Business services nec";
export const Sector72 = "72 Recreational and other services";
export const Sector73 = "73 Public Administration and defense";
export const Sector74 = "74 Education";
export const Sector75 = "75 Human health and social work acitvities";
export const Sector76 = "76 Dwellings";
export const AttributionOutstanding = "Attribution - Outstanding amount";
export const AttributionShareCommitted = "Attribution share - Committed";
export const AttributionShareOutstanding = "Attribution share - Outstanding";

export const Strings = [
    Indicator,
    SubIndicator,
    Scope,
    SubScope,
    LDC,
    Income,
    Region,
    SubRegion,
    Country,
    EconAct,
    EconActCode,
    Client,
    ClientType,
    Investee,
    Comment,
];

export const Numeric = [
    ReportingYear,
    FiscalYear,
    ProjectTimeline,
    Revenue,
    OutstandingAmount,
    PowerProduction,
    AttributedTotal,
    Total,
    YouthEmployment,
    ConfidenceLevel,
    ImpactOutstanding,
    ImpactRevenue,
    Sector1,
    Sector2,
    Sector3,
    Sector4,
    Sector5,
    Sector6,
    Sector7,
    Sector8,
    Sector9,
    Sector10,
    Sector11,
    Sector12,
    Sector13,
    Sector14,
    Sector15,
    Sector16,
    Sector17,
    Sector18,
    Sector19,
    Sector20,
    Sector21,
    Sector22,
    Sector23,
    Sector24,
    Sector25,
    Sector26,
    Sector27,
    Sector28,
    Sector29,
    Sector30,
    Sector31,
    Sector32,
    Sector33,
    Sector34,
    Sector35,
    Sector36,
    Sector37,
    Sector38,
    Sector39,
    Sector40,
    Sector41,
    Sector42,
    Sector43,
    Sector44,
    Sector45,
    Sector46,
    Sector47,
    Sector48,
    Sector49,
    Sector50,
    Sector51,
    Sector52,
    Sector53,
    Sector54,
    Sector55,
    Sector56,
    Sector57,
    Sector58,
    Sector59,
    Sector60,
    Sector61,
    Sector62,
    Sector63,
    Sector64,
    Sector65,
    Sector66,
    Sector67,
    Sector68,
    Sector69,
    Sector70,
    Sector71,
    Sector72,
    Sector73,
    Sector74,
    Sector75,
    Sector76,
    AttributionOutstanding,
    AttributionShareCommitted,
    AttributionShareOutstanding,
];

export const ALL = [...Strings, ...Numeric];
