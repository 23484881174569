import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
type Props = {
    text: string;
    value: number | string | undefined;
};
export const InvestmentRow = ({ text, value }: Props) => {
    const { t } = useTranslation();
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-around">
            <Typography fontSize="14px" width="190px">
                {text}
            </Typography>
            <Stack
                sx={{ backgroundColor: "#18405B", borderRadius: "3px" }}
                width="70px"
                height="20px"
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                px={0.5}
            >
                <Typography fontSize="13px" color="#FFFFFF">
                    {value === undefined ? "n/a" : t("intlNumber", { val: value })}
                </Typography>
            </Stack>
        </Stack>
    );
};
