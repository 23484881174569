import { Button, Container, Typography } from "@mui/material";
import { ErrorDialog } from "../components/ErrorDialog";
import { useState } from "react";
import { UploadError } from "../hooks/useUploadFile";

export const Widgets = () => {
    const [error, setError] = useState<UploadError | null>(null);
    return (
        <Container>
            <Typography>Error dialog</Typography>
            <Button
                onClick={() => {
                    setError({ msg: "Simple error" });
                }}
            >
                Simple error
            </Button>
            <Button
                onClick={() => {
                    setError({ msg: "Error with details", error: "Error details\n detail \n detail" });
                }}
            >
                Error with details
            </Button>
            <Button
                onClick={() => {
                    setError({
                        msg: "Error with details",
                        data: ["column missing", "weird data", "something else"],
                    });
                }}
            >
                Error with multiple details
            </Button>
            <ErrorDialog error={error} reset={() => setError(null)} />
        </Container>
    );
};
