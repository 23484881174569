import { Stack } from "@mui/material";
import { ReactNode } from "react";
import { resultCardContainerSettings } from "../utils/dashboardSettingsConstants";

interface ResultCardContainerProps {
  children: ReactNode;
}
export const ResultCardContainer: React.FC<ResultCardContainerProps> = ({
  children,
}) => {
  return (
    <Stack
      sx={resultCardContainerSettings.style}
      width={resultCardContainerSettings.width}
      height={resultCardContainerSettings.height}
      py={resultCardContainerSettings.padding}
      alignItems="center"
    >
      {children}
    </Stack>
  );
};
