import { Stack } from "@mui/material";
import { CardTitle } from "./Cardtitle";
import { ResultCard } from "./ResultCard";
import { ResultSummary } from "./resultsummary/ResultSummary";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DataContext } from "../contexts/DataContext";
import { valueAddedColors } from "../utils/colorConstants";
import { resultCardSettings } from "../utils/dashboardSettingsConstants";

export const ValueAddedCard = () => {
    const { t } = useTranslation();
    const { data } = useContext(DataContext);
    const valueAddedSummary = data?.valueAddedSummary;
    return (
        valueAddedSummary && (
            //@ts-ignore
            <Stack {...resultCardSettings}>
                <CardTitle tColor={valueAddedColors.title} text={t("valueAddedCard.cardTitle")} />
                <ResultCard
                    bgColor={valueAddedColors.cardBg}
                    value={valueAddedSummary?.columnTotals[0] ? t("intlNumber", { val: valueAddedSummary?.columnTotals[0].toFixed(0) }) : "n/a"}
                    description={t("valueAddedCard.resultCard.description")}
                />
                <ResultSummary
                    bgColor={valueAddedColors.tableBg}
                    data={valueAddedSummary}
                    translationPrefix="valueAddedCard.resultSummary"
                    columnSpacing={7}
                    columnRightPadding={0}
                    dataType="valueadded"
                    isDashboard
                />
            </Stack>
        )
    );
};
